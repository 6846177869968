import './home.scss';

// console.log('home.js');

setTimeout(() => {
    jQuery(".ff-bef-aft-arrows .swiper").each(function() {
        var swiperInstance = jQuery(this).data("swiper");

        swiperInstance.params.navigation.nextEl = jQuery(this).closest(".ff-bef-aft-arrows ").find(".button_arrows .next")[0];
        swiperInstance.params.navigation.prevEl = jQuery(this).closest(".ff-bef-aft-arrows ").find(".button_arrows .prev")[0];
        
        swiperInstance.destroy(false);
        new Swiper(this, swiperInstance.params);
    });
}, 1000);